// Common
import { Modal } from "bootstrap";

// Common
import { default as init } from "./common/base";

// Helpers
import { Component } from "./helpers/component";

// Components
import { Menu } from "../../components/menu/menu";

//import { Header } from "../../components/header/header";
import DataTable from "datatables.net-dt";
import "datatables.net-buttons/js/buttons.colVis"


// Registering components
Component.register("Menu", Menu);

init();

document.addEventListener('DOMContentLoaded', () => {

    $('button[data-clear-filters]').click(function(e) {
      var url = $(this).attr("data-clear-filters");
      window.location.href = url;
     });

    /*  const value = document.querySelector("#importo_value");
      const input = document.querySelector("#importo_range");
      value.textContent = input.value;
      input.addEventListener("input", (event) => {
        value.textContent = event.target.value;
      });
    */

});
